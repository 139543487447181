import React from 'react';
import { MapPin } from 'lucide-react';

const LocationMap = () => {
  return (
    <div className="bg-black bg-opacity-30 p-4 rounded-lg">
      <h3 className="text-xl font-semibold mb-2 text-shadow">Location</h3>
      <div className="aspect-video relative mb-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3042.7476904428745!2d-74.0901!3d40.3037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c22426e6a7a8d9%3A0x8e7a0b7c0e7a0b7c!2s70%20Silvercrest%20Dr%2C%20Tinton%20Falls%2C%20NJ%2007724!5e0!3m2!1sen!2sus!4v1629788301234!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          className="rounded-lg"
        ></iframe>
      </div>
      <div className="flex items-start space-x-2">
        <MapPin className="text-red-400 mt-1 flex-shrink-0" />
        <div>
          <p className="text-white text-shadow">70 Silvercrest Drive</p>
          <p className="text-white text-shadow">Tinton Falls, NJ 07724</p>
        </div>
      </div>
    </div>
  );
};

export default LocationMap;