import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, ArrowRight, X, Calendar, Clock } from 'lucide-react';
import Modal from 'react-modal';
import AnimatedSilvercrestLogo from '../Logo';
import Snow from '../Snow';
import DonationInfo from '../DonationSection/DonationInfo';
import WeatherWidget from '../WeatherWidget';
import LocationMap from '../LocationMap';
import ShareButtons from '../ShareButtons';
import { event } from '../../utils/analytics';

Modal.setAppElement('#root');

const LandingPage = () => {
  const [entered, setEntered] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
    event({
      action: 'view',
      category: 'gallery',
      label: `image_${imageSrc}`,
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDonateClick = () => {
    event({
      action: 'click',
      category: 'donation',
      label: 'donate_button',
      value: 1
    });
  };

  return (
    <div className="min-h-screen relative overflow-hidden font-sans">
      <video
        autoPlay
        loop
        muted
        playsInline
        poster="/images/video-poster.jpg"
        preload="metadata"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src="/videos/drone-footage.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 z-10"></div>
      <Snow />
      <div className="relative z-20 min-h-screen text-white">
        {!entered ? (
          <motion.div 
            className="flex flex-col items-center justify-center h-screen p-4 relative"
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.2
                }
              }
            }}
          >
            <motion.div variants={fadeIn} className="mb-8">
              <AnimatedSilvercrestLogo />
            </motion.div>
            <motion.h1 variants={fadeIn} className="text-4xl md:text-6xl font-bold mb-4 text-center text-shadow">
              Silvercrest Illuminations
            </motion.h1>
            <motion.p variants={fadeIn} className="text-xl md:text-2xl mb-8 text-center max-w-2xl text-shadow">
              Experience the magic of our annual Christmas light show
            </motion.p>
            <motion.div variants={fadeIn} className="mb-8 text-center bg-black bg-opacity-30 p-4 rounded-lg w-full max-w-md">
              <p className="text-2xl font-bold text-shadow">The Show Is Now Running!</p>
              <p className="text-lg text-gray-300 text-shadow mt-2">Join us nightly from 5:00 PM to 12:00 AM</p>
            </motion.div>
            <motion.div variants={fadeIn} className="mb-8 text-center">
              <a 
                href="https://michaelsquest.org"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleDonateClick}
                className="bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-full text-xl font-semibold transition-colors duration-300 inline-flex items-center"
              >
                Donate Now
              </a>
              <p className="mt-2 text-sm text-gray-300 text-shadow">Support Michael's Quest</p>
            </motion.div>
            <motion.button
              variants={fadeIn}
              onClick={() => setEntered(true)}
              className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 rounded-full text-xl font-semibold transition-colors duration-300 flex items-center"
            >
              Enter <ChevronRight className="ml-2" />
            </motion.button>
            <motion.div variants={fadeIn} className="absolute bottom-4 left-0 right-0 text-center">
              <p className="text-sm text-gray-300 text-shadow">
                In partnership with{" "}
                <a 
                  href="https://a1wealthmanagement.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 hover:text-white underline transition-colors duration-300"
                >
                  A1 Wealth Management
                </a>
              </p>
            </motion.div>
          </motion.div>
        ) : (
          <div className="container mx-auto px-4 py-12">
            <header className="mb-16 text-center bg-black bg-opacity-30 p-6 rounded-lg">
              <h1 className="text-4xl md:text-5xl font-bold mb-4 text-shadow">Silvercrest Illuminations Presents</h1>
              <h2 className="text-3xl md:text-4xl font-semibold mb-4 text-shadow">Olivadoti Christmas Lightshow</h2>
              <p className="text-xl text-gray-300 text-shadow">Where holiday magic comes to life</p>
              <div className="mt-6">
                <ShareButtons />
              </div>
            </header>
            
            <section className="mb-16 bg-black bg-opacity-30 p-6 rounded-lg">
              <h2 className="text-3xl font-semibold mb-6 text-shadow">About Our Light Show</h2>
              <p className="text-lg text-gray-300 max-w-3xl text-shadow">
                For over a decade, Glen Olivadoti has been transforming the neighborhood into a breathtaking winter wonderland. Glen's annual Christmas light show features Tens of thousands of lights synchronized to festive music, creating an unforgettable experience for visitors of all ages.
              </p>
            </section>

            <section className="mb-16 bg-black bg-opacity-30 p-6 rounded-lg">
              <h2 className="text-3xl font-semibold mb-6 text-shadow">Experience the Magic in Tinton Falls</h2>
              <p className="text-lg text-gray-300 max-w-3xl text-shadow mb-4">
                Located in the heart of Tinton Falls, NJ, our residential Christmas light display has become a beloved holiday tradition in Monmouth County. Just minutes from Red Bank, Middletown, and surrounding New Jersey communities, our synchronized light show offers a magical experience for families seeking holiday lights near them. Visit us at 70 Silvercrest Drive for a festive display that supports a great cause.
              </p>
              <p className="text-lg text-gray-300 max-w-3xl text-shadow">
                Whether you're searching for "Christmas lights near me" or planning a special holiday outing in Monmouth County, our free nightly display runs from 5:00 PM to 12:00 AM, featuring tens of thousands of lights synchronized to holiday music. Easily accessible from the Garden State Parkway and local routes, we're a must-visit destination for holiday light viewing in Central New Jersey.
              </p>
            </section>

            <DonationInfo />

            <section className="mb-16">
              <h2 className="text-3xl font-semibold mb-6 text-shadow">Plan Your Visit</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <LocationMap />
                </div>
                <div className="space-y-6">
                  <WeatherWidget />
                  <div className="bg-black bg-opacity-30 p-6 rounded-lg">
                    <div className="flex items-center mb-4">
                      <Calendar className="mr-2 text-red-400" />
                      <p className="text-lg text-shadow">November 29, 2024 - January 2, 2025</p>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 text-red-400" />
                      <p className="text-lg text-shadow">Every night from 5:00 PM to 12:00 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            <section>
              <h2 className="text-3xl font-semibold mb-6 text-shadow">Light Show Gallery</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <div key={i} className="aspect-square rounded-lg overflow-hidden shadow-lg group relative">
                    <img 
                      src={`/images/lightshow${i}-thumb.jpg`} 
                      alt={`Christmas light display at Olivadoti Christmas Lightshow in Tinton Falls, NJ - Image ${i}`}
                      className="w-full h-full object-cover"
                    />
                    <div 
                      className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-50 transition-all duration-300 flex items-center justify-center cursor-pointer"
                      onClick={() => openModal(`/images/lightshow${i}.jpg`)}
                    >
                      <span className="text-white font-semibold text-shadow">View Larger</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            
            <footer className="mt-16 text-center">
              <p className="text-sm text-gray-300 text-shadow mt-4">
                In partnership with{" "}
                <a 
                  href="https://a1wealthmanagement.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 hover:text-white underline transition-colors duration-300"
                >
                  A1 Wealth Management
                </a>
              </p>
            </footer>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Lightbox"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="relative w-full h-full flex items-center justify-center">
          <img 
            src={selectedImage} 
            alt="Olivadoti Christmas Lightshow - Full size display"
            className="max-w-full max-h-full object-contain" 
          />
          <button 
            onClick={closeModal}
            className="absolute top-4 right-4 text-white bg-red-600 hover:bg-red-700 rounded-full p-2 transition-colors duration-300"
          >
            <X size={24} />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default LandingPage;