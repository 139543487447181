import React from 'react';
import { Share2, Facebook, Twitter, Mail } from 'lucide-react';

const ShareButtons = () => {
  const pageUrl = encodeURIComponent(window.location.href);
  const title = encodeURIComponent("Olivadoti Christmas Lightshow - Where holiday magic comes to life!");
  const description = encodeURIComponent("Experience the magic of our annual Christmas light show while supporting Michael's Quest. Every night from 5:00 PM to 12:00 AM.");

  return (
    <div className="flex items-center space-x-4">
      <span className="text-white text-shadow flex items-center">
        <Share2 className="mr-2" size={18} /> Share:
      </span>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-blue-400 transition-colors duration-300"
      >
        <Facebook size={24} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${pageUrl}&text=${title}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-blue-400 transition-colors duration-300"
      >
        <Twitter size={24} />
      </a>
      <a
        href={`mailto:?subject=${title}&body=${description}%0A%0A${pageUrl}`}
        className="text-white hover:text-blue-400 transition-colors duration-300"
      >
        <Mail size={24} />
      </a>
    </div>
  );
};

export default ShareButtons;