import React from 'react';
import LandingPage from './components/LandingPage';
import { setFavicon } from './components/Logo';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    setFavicon();
  }, []);

  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;