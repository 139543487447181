import React, { useEffect, useState } from 'react';
import { Cloud, Sun, CloudRain, Snowflake } from 'lucide-react';

const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
          `https://api.weatherapi.com/v1/forecast.json?key=5750ab49b2654df8940203425241012&q=Tinton+Falls,NJ&days=1`
        );
        const data = await response.json();
        setWeather(data);
      } catch (error) {
        console.log('Error fetching weather:', error);
      }
      setLoading(false);
    };

    fetchWeather();
  }, []);

  if (loading) {
    return (
      <div className="bg-black bg-opacity-30 p-4 rounded-lg">
        <p className="text-white text-shadow">Loading weather...</p>
      </div>
    );
  }

  const getWeatherIcon = (condition) => {
    if (!condition) return <Sun className="text-yellow-400" />;
    const code = condition.code;
    if (code === 1000) return <Sun className="text-yellow-400" />;
    if (code >= 1003 && code <= 1030) return <Cloud className="text-gray-300" />;
    if (code >= 1063 && code <= 1201) return <CloudRain className="text-blue-300" />;
    if (code >= 1204 && code <= 1237) return <Snowflake className="text-blue-200" />;
    return <Sun className="text-yellow-400" />;
  };

  return (
    <div className="bg-black bg-opacity-30 p-4 rounded-lg">
      <h3 className="text-xl font-semibold mb-2 text-shadow">Tonight's Weather</h3>
      <div className="flex items-center space-x-2">
        {getWeatherIcon(weather?.current?.condition)}
        <div>
          <p className="text-white text-shadow">
            {weather?.current?.temp_f}°F
          </p>
          <p className="text-sm text-gray-300 text-shadow">
            {weather?.current?.condition?.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;