import React from 'react';

const DonationInfo = () => {
  return (
    <section className="mb-16 bg-black bg-opacity-30 p-6 rounded-lg">
      <h2 className="text-3xl font-semibold mb-6 text-shadow text-center">Light Up Hope This Holiday Season</h2>
      
      <div className="space-y-6 text-lg text-gray-300">
        <p className="text-shadow">
          Welcome to our annual Christmas Lights Show! This magical display isn't just about spreading holiday cheer - it's about illuminating hope for families affected by autism through our partnership with Michael's Quest.
        </p>
        
        <p className="text-shadow">
          This year's show holds special meaning for our community, as several of our friends and family members are navigating life with newly diagnosed children on the autism spectrum. Every donation helps Michael's Quest provide vital support, resources, and services to local families affected by autism.
        </p>

        <div className="my-8">
          <h3 className="text-xl font-semibold mb-4 text-shadow">Your generosity helps:</h3>
          <ul className="list-none space-y-2 pl-4">
            <li className="flex items-start">
              <span className="text-red-400 mr-2">•</span>
              Fund early intervention programs
            </li>
            <li className="flex items-start">
              <span className="text-red-400 mr-2">•</span>
              Support family education and resources
            </li>
            <li className="flex items-start">
              <span className="text-red-400 mr-2">•</span>
              Provide specialized therapy services
            </li>
            <li className="flex items-start">
              <span className="text-red-400 mr-2">•</span>
              Create inclusive community programs
            </li>
          </ul>
        </div>

        <p className="text-shadow">
          Whether you can give $1 or $100, every donation makes a difference in helping families in our community. Together, we can brighten not just the holiday season, but the future for children with autism and their families.
        </p>

        <p className="text-shadow font-semibold">
          Thank you for being part of our mission to spread light, hope, and understanding.
        </p>

        <div className="mt-8 text-center">
          <a 
            href="https://michaelsquest.org"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-full text-xl font-semibold transition-colors duration-300 inline-flex items-center"
          >
            Donate Now
          </a>
          <p className="mt-4 text-sm italic">
            100% of donations go directly to Michael's Quest to support local families affected by autism.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DonationInfo;